<template>
  <div class="divBox">
    <el-card class="mt14">
      <el-button type="primary" @click="add" size="mini">新增</el-button>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        highlight-current-row
      >
        <el-table-column prop="sort" label="排序" min-width="120" />
        <el-table-column prop="title" label="标题" min-width="120" />
        <el-table-column prop="remark" label="备注" min-width="120" />
        <el-table-column label="状态" min-width="120">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.state"
              :active-value="1"
              :inactive-value="2"
              active-text="开启"
              inactive-text="关闭"
              @change="onchangeIsShow(scope.row)"
            >
            </el-switch>
          </template> 
        </el-table-column>

        <el-table-column label="操作" min-width="60" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              @click="onEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              icon="el-icon-delete"
              @click="handleDelete(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="标题:" prop="title">
          <el-input
            class="inputWidth"
            size="mini"
            placeholder="请输入标题"
            maxlength="50"
            v-model="form.title"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序:" prop="sort">
          <el-input
            style="width: 59%; text-align: left"
            type="number"
            size="mini"
            placeholder="请输入排序"
            v-model="form.sort"
            controls-position="right"
            :min="1"
            :max="999999"
          ></el-input>
        </el-form-item>

        <el-form-item label="备注:" prop="remark">
          <el-input
            class="inputWidth"
            size="mini"
            placeholder="请输入备注"
            maxlength="100"
            v-model="form.remark"
          ></el-input>
        </el-form-item>

        <el-form-item label="内容:" prop="content">
          <FormEditor
            :details="form.content"
            @editoChange="editoChange"
            :disabled="false"
          />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('form')">提交</el-button>
          <!-- <el-button @click="resetForm('form')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  articleList,
  articleAdd,
  articleView,
  articleUpdate,
  articleDel,
  articleStateUp,
} from "@/api/decorate/decorate";
import {
  getStoreAllList, //门店接口
} from "@/api/user/list";
import FormEditor from "@/components/formEditor/index";
import FormImgUpload from "@/components/formComponents/formImgUpload";
export default {
  name: "banner",
  components: {
    FormImgUpload,
    FormEditor,
  },
  data() {
    return {
      title: "新增文章/专题",
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        status: "1",
        storeId: "",
      },
      storeList: {},
      form: {
        sort:'',
        title:'',
        remark:'',
        content:''
      },
      listLoading: false,
      tableData: {
        data: [{}],
        total: 0,
      },
      dialogVisible: false,
      dialogVisibleLink: false,
      rules: {
        title: [{ required: true, message: "请输入", trigger: "blur" }],
        image: [{ required: true, message: "上传", trigger: "blur" }],
        sort: [{ required: true, message: "请输入", trigger: "blur" }],
        type: [{ required: true, message: "请输入", trigger: "blur" }],
        storeId: [{ required: true, message: "请选择", trigger: "blur" }],
        aboutName: [{ required: true, message: "请选择", trigger: "blur" }],
      },
    };
  },
  mounted() {
   this.getList()
  },
  methods: {
    editoChange(e) {
      this.form.content = e;
    },
    //获取门店接口
   
    
    getList() {
      this.listLoading = true;
      articleList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },

    // 删除
    handleDelete(id) {
      let that = this;
      this.$confirm(`删除这条数据`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            articleDel(id)
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code === 200) {
                  that.getList();
                  that.$message.success(res.msg);
                } else {
                  that.$message.error(res.msg);
                }
              })
              .catch((err) => {
                this.$message.error(err.msg);
              });
          }
        },
      });
    },

    onchangeIsShow(row) {
      articleStateUp({ id: row.id, state: row.state })
        .then(({ msg }) => {
          this.getList();
          this.$message.success(msg);
        })
        .catch(({ msg }) => {
          this.$message.error(msg);
          this.getList();
        });
    },
    pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    storeChange() {
      this.form.aboutId = "";
      this.form.aboutName = "";
    },
    getGoodsItem(row) {
      this.form.aboutId = row.id;
      this.form.aboutName = row.name;
      this.dialogVisibleLink = false;
    },
    formSubmit() {},
    add() {
      this.form.id=''
      this.title = "新增文章/专题"
      this.dialogVisible = true;
    },
    // 编辑
    onEdit(row) {
      this.title = "编辑文章/专题"
      articleView(row.id)
        .then((res) => {
          this.form = JSON.parse(JSON.stringify(res.data));
          this.dialogVisible = true;
        })
        .catch(({ msg }) => {

        });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.dialogVisible = false;
    },
    handleCloseLink() {
      this.dialogVisibleLink = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            articleUpdate(this.form).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              if (res.code === 200) {
                this.$message.success("修改成功");
                this.getList();
                this.handleClose();
                // done();
              } else {
                this.$message.error(res.msg);
                // done();
              }
            });
          } else {
            articleAdd(this.form).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.getList();
                this.handleClose();
                // done();
              } else {
                this.$message.error(res.msg);
                // done();
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
  
<style lang="scss" scoped>
.changeLink {
  width: 200px;
  height: 30px;
  border: 1px solid #dcdfe6;
  line-height: 30px;
  font-size: 14px;
  padding-left: 14px;
  box-sizing: border-box;
  cursor: pointer;
}
.inputWidth {
  width: 300px;
}
.inputBox {
  width: 100%;
  display: flex;
  align-items: center;
}
</style>
  